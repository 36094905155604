<template>
  <s-card
    class="stats-card pa-4 pa-lg-6 overflow-hidden"
    style="height: 100%;"
  >
    <div class="d-flex justify-space-between align-center">
      <s-text
        weight="medium"
        size="md-m"
      >
        {{ title }}
      </s-text>

      <div class="d-flex justify-end align-center">
        <s-btn
          icon
          depressed
          style="min-height: 40px !important"
          class="mr-2"
          @click="expand()"
        >
          <v-icon>mdi-format-list-bulleted-square</v-icon>
        </s-btn>
        <s-btn
          outlined
          elevation="0"
          color="primary"
          small
          @click="refresh()"
          :style="{ fontSize: '14px' }"
        >
          Refresh
        </s-btn>
      </div>
    </div>

    <div class="d-flex content">
      <v-progress-circular
        v-if="isWorking"
        indeterminate
        class="ma-auto"
        color="primary"
        :size="20"
        :width="3"
      />
      <distribution-analytics
        v-else
        v-bind="$attrs"
        :chart-data="statusDistribution"
        :storeKey="key"
        :show-default-legend="false"
        class="mt-10"
        @click="(data) => { $emit('click', data) }"
      >
        <template #legend="{ orderedList, drillDetails }">
          <ul class="pie__legends mt-8 pl-0">
            <li
              class="legend-holder"
              v-for="data in orderedList"
              :key="data.key"
              :ripple="false"
              :class="{
                'd-flex': true,
                'align-center': true,
              }"
              @click="drillDetails(data)"
            >
              <span class="legend">
                <span class="pie__dot mr-2" :style="{ background: data.color }" />
                <s-text weight="medium" size="sm" class="mr-1 ml-1">
                  {{ data.percent }}% -
                </s-text>
                <user-snippet :id="data.legend" />
              </span>
            </li>
          </ul>
        </template>
      </distribution-analytics>
    </div>
  </s-card>
</template>

<script>
import DistributionAnalytics from '@/components/cards/distribution-analytics/DistributionAnalytics'
import { DistributionCardMixin } from '@/components/cards/distribution-analytics/distribution-analytics-mixin.js'
import UserSnippet from '@/components/cards/user/UserSnippet'

export default {
  name: 'DistributionCard',
  mixins: [DistributionCardMixin],
  components: {
    'distribution-analytics': DistributionAnalytics,
    'user-snippet': UserSnippet,
  },
}
</script>

<style scoped>
.content {
  width: 100%;
  min-height: 290px;
}
</style>
