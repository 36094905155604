import { mapState } from 'vuex'

const DataListMixin = {
  props: {
    getter: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState({
      data (state, getters) {
        return getters[this.getter]
      },
    }),
    orderedList: function () {
      if (!this.data) return null

      const tempChartData = this.data
      const total = tempChartData.reduce(
        (acc, data) => acc + data.count,
        0
      )

      const dataset = []
      // process chart
      tempChartData.forEach((data, i) => {
        // for unknown
        let dataCount = data.count

        const _legend = !data.label
          ? 'Unknown'
          : data.label

        // check if we already have unknown
        const unknownIndex = dataset.findIndex(data => data.label === 'Unknown')
        if (_legend === 'Unknown' && unknownIndex !== -1) {
          const unknownDataset = dataset[unknownIndex]
          dataset.splice(unknownIndex, 1)
          dataCount += parseInt(unknownDataset.count)
        }

        dataset.push({
          label: _legend,
          count: dataCount.toFixed(2),
          percent: (total > 0)
            ? ((dataCount / total) * 100).toFixed(1)
            : 0.0,
        })
      })

      const result = dataset.sort((a, b) => b.count - a.count)

      // if the sum of percentage is less than 1, return an empty array
      const dataSetIsEmpty = result.reduce((total, each) => each.percent + total, 0) < 1
      if (dataSetIsEmpty) return []

      return result
    },
  },
  data () {
    return {
      isLoading: false,
    }
  },
}

export {
  DataListMixin,
}
