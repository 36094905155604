<template>
  <loader
    v-if="isLoading"
    message="Loading ...."
  />
  <div v-else>
    <empty-state
      v-if="data && orderedList.length === 0"
      message="No data available"
      hint="Please check back."
      style="height: 350px"
      class="ma-auto"
    />
    <v-container v-else class="px-8">
      <v-row class="pa-1 ma-0 my-3" style="background: #e0f4ff; border-radius: 8px;">
        <v-col class="d-flex flex-column py-0" cols="6">
          <s-text weight="medium" color="gray" size="sm">
            Label
          </s-text>
        </v-col>
        <v-col class="d-flex flex-column py-0" cols="3">
          <s-text weight="medium" color="gray" size="sm">
            Value
          </s-text>
        </v-col>
        <v-col class="d-flex flex-column py-0" cols="3">
          <s-text weight="medium" color="gray" size="sm">
            Percentage
          </s-text>
        </v-col>
      </v-row>
      <v-row
        v-for="log in orderedList"
        :key="log.label"
        class="pa-0 ma-0 mb-3 app-card app-card-content"
        @click="() => { $emit('click', log.label) }"
      >
        <v-col class="d-flex flex-column" cols="6">
          <div>
            {{ log.label }}
          </div>
        </v-col>
        <v-col class="d-flex flex-column" cols="3">
          <div>
            {{ log.count }}
          </div>
        </v-col>
        <v-col class="d-flex flex-column" cols="3">
          <div>
            {{ log.percent }}%
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>

import Loader from '@/components/cards/Loader'
import EmptyState from '@/components/cards/EmptyState'
import { DataListMixin } from '@/components/cards/lists/lists-mixin.js'
export default {
  name: 'DataList',
  mixins: [DataListMixin],
  components: {
    loader: Loader,
    'empty-state': EmptyState,
  },
}
</script>
