<template>
  <div
    class="d-flex align-center"
    @click="viewUser"
  >
    <v-avatar class="mr-2" size="24">
      <v-img
        aspect-ratio="1"
        max-height="24"
        max-width="24"
        cover
        :lazy-src="require(`@/assets/avatars/user.svg`)"
        :src="userAvatarImage"
      />
    </v-avatar>
    <div class="d-flex align-center name-holder" style="width: 100%;">
      <v-skeleton-loader
        v-if="isLoading"
        type="card-heading"
        style="min-width: 64px"
      />
      <s-text
        weight="medium"
        color="grayTextAlt"
        style="cursor: pointer;"
        v-else
      >
        {{ user ? user.bio.name : id }}
      </s-text>
    </div>
  </div>
</template>

<script>
import { UserMixin } from '@/components/cards/user/user-mixin.js'
export default {
  name: 'UserCard',
  mixins: [UserMixin],
  methods: {
    viewUser () {
      return null
    },
  },
}
</script>

<style lang="scss" scoped>
:deep {
  .v-skeleton-loader__card-heading {
    background: none !important;
  }

  .v-skeleton-loader__heading {
    margin: 0;
    height: 20px;
    width: 60%;
  }

  .name-holder {
    .v-skeleton-loader__heading {
      width: 100%;
    }
  }

  .v-image__image--preload {
    filter: none;
  }
}
</style>
