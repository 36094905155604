<template>
  <v-container>
    <v-row>
      <v-col>
        <v-row no-gutters class="mb-8">
          <v-col cols="4" class="pa-0 pr-4">
            <top-stats-card class="mb-8" />
          </v-col>
          <v-col cols="4" class="pa-0 px-4">
            <last-connected-stats-card class="mb-8" />
          </v-col>
          <v-col cols="4" class="pa-0 pl-4">
            <online-connected-stats-card class="mb-8" />
          </v-col>
        </v-row>
        <multi-timeseries-analytics
          class="mb-8"
          title="Telco Usage"
          _key="Telco_Usage"
        />
        <timeseries-analytics
          class="mb-8"
          title="User Acquisition"
          _key="NewUser"
        />
        <timeseries-analytics
          class="mb-8"
          title="Daily Active Users"
          _key="Lastseen"
        />
        <v-row no-gutters class="mb-8">
          <v-col cols="6" class="pa-0 pr-4">
            <distribution-card
              title="Telco Distribution"
              _key="Telco"
              type="status"
              :params="{type: 'telco'}"
              @expand="showListDialog"
            />
          </v-col>
          <v-col cols="6" class="pa-0 pl-4">
            <distribution-card
              title="Country Distribution"
              _key="GeoCountry"
              type="content"
              :params="{type: 'geo', level: 'country'}"
              @expand="showListDialog"
            />
          </v-col>
        </v-row>
        <v-row no-gutters class="mb-8">
          <v-col cols="6" class="pa-0 pr-4">
            <distribution-card
              title="State Distribution"
              _key="GeoState"
              type="content"
              :params="{type: 'geo', level: 'state', country: 'Nigeria'}"
              @expand="showListDialog"
            />
          </v-col>
          <v-col cols="6" class="pa-0 pl-4">
            <distribution-card
              title="App Version Distribution"
              _key="AppVersion"
              type="content"
              :params="{type: 'app_version'}"
              @expand="showListDialog"
            />
          </v-col>
        </v-row>
        <v-row no-gutters class="mb-8">
          <v-col cols="6" class="pa-0 pr-4">
            <distribution-card
              title="Top Datafree Websites"
              _key="ZerodataWebsites"
              type="content"
              :params="{ path: 'websites', label_key: 'fqdn' }"
              @expand="showListDialog"
            />
          </v-col>
          <v-col cols="6" class="pa-0 pl-4">
            <distribution-user-card
              title="Top Datafree Users"
              _key="ZerodataUsers"
              type="content"
              :params="{ path: 'users', label_key: 'user' }"
              @expand="showUserListDialog"
              @click="(data) => { viewUser(data.legend) }"
            />
          </v-col>
        </v-row>
        <timeseries-analytics
          class="mb-8"
          title="Messages"
          _key="Messages"
        />
        <timeseries-analytics
          class="mb-8"
          title="Media"
          _key="Media"
        />
      </v-col>
    </v-row>
    <s-dialog
      v-model="showDialog"
      persistent
      isModal
      :title="modalTitle"
      :close-default="modalCloseDafaultSettings"
      @close="showListModal = false; showUserListModal = false; listModalFn = () => {};"
    >
      <data-list
        v-if="showListModal"
        :getter="getter"
        @click="listModalFn"
      />
      <user-data-list
        v-if="showUserListModal"
        :getter="getter"
        @click="listModalFn"
      />
      <active-connections
        v-if="showModal"
      />
      <online-connections
        v-if="showOnlineModal"
      />
    </s-dialog>
  </v-container>
</template>

<script>
import TopStatsCard from '@/components/cards/TopStatsCard'
import LastConnectedStatsCard from '@/components/cards/LastConnectedStatsCard'
import OnlineConnectedStatsCard from '@/components/cards/OnlineConnectedStatsCard'
import TimeseriesAnalytics from '@/components/cards/TimeseriesAnalytics'
import MultiTimeseriesAnalytics from '@/components/cards/MultiTimeseriesAnalytics'
import DistributionCard from '@/components/cards/distribution-analytics/DistributionCard'
import DistributionUserCard from '@/components/cards/distribution-analytics/DistributionUserCard'
import DataList from '@/components/cards/lists/DataList'
import UserDataList from '@/components/cards/lists/UserDataList'
import ActiveConnections from '@/views/dashboard/ActiveConnections'
import OnlineConnections from '@/views/dashboard/OnlineConnections'
export default {
  components: {
    'top-stats-card': TopStatsCard,
    'data-list': DataList,
    'user-data-list': UserDataList,
    'timeseries-analytics': TimeseriesAnalytics,
    'multi-timeseries-analytics': MultiTimeseriesAnalytics,
    'distribution-card': DistributionCard,
    'distribution-user-card': DistributionUserCard,
    'last-connected-stats-card': LastConnectedStatsCard,
    'online-connected-stats-card': OnlineConnectedStatsCard,
    'online-connections': OnlineConnections,
    'active-connections': ActiveConnections,
  },
  computed: {
    showDialog () {
      return this.showListModal || this.showUserListModal || !!this.showOnlineModal || !!this.showModal
    },
    modalCloseDafaultSettings () {
      return !this.showListModal && !this.showUserListModal
    },
    modalTitle () {
      return this.title ? this.title : ''
    },
  },
  data () {
    return {
      showModal: this.$route.meta.showModal,
      showOnlineModal: this.$route.meta.showOnlineModal,
      showListModal: false,
      showUserListModal: false,
      listModalFn: () => {},
      title: '',
      getter: null,
    }
  },
  watch: {
    '$route.meta': {
      handler ({ showModal, showOnlineModal, modalTitle }) {
        this.showModal = showModal
        this.showOnlineModal = showOnlineModal
        this.title = modalTitle
      },
      immediate: true,
    },
  },
  methods: {
    showListDialog ({ title, getter }) {
      this.title = title
      this.getter = getter
      this.showListModal = true
    },
    showUserListDialog ({ title, getter }) {
      this.title = title
      this.getter = getter
      this.showUserListModal = true

      this.listModalFn = this.viewUser
    },
    viewUser (id) {
      // open in new tab as it takes a long time for this endpoint to return data
      const routeData = this.$router.resolve({ name: 'appDetails', params: { id } })
      window.open(routeData.href, '_blank')
    },
  },
  destroyed () {
    this.$store.dispatch('analytic/clear')
  },
}
</script>

<style lang="scss" scoped></style>
